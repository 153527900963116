body {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    background-color: #fff;
}
/* start of navbar*/
.lang-btn{
    /* margin-right: -30px; */
    width: 50px;
    height: 50px;
}
.lang-btn button{
    border: #fff 1px solid;
    width: 40px;
    height: 40px;
    margin: 0 5px;
    color: #fff;
}
.scrolled button{
    border: #000 1px solid !important;
    color: #000 !important;
}
.navbar{
    background-color:transparent !important;
    border-color: transparent !important;
    padding: 10px 0;    
    z-index: 1030;
}
.navbar ul li.active a {
    color: #f6383f !important;
}
.navbar ul li a {
    background: transparent;
    text-transform: uppercase;
    /* font-family: 'Lato', sans-serif; */
    font-weight: 600;
    color: #fff;
    /* letter-spacing: 1px; */
    margin: 0 0.7rem;
}
.navbar ul li a:hover{
    color: #f6383f !important;
}
.scrolled .navbar-nav>li>a{
    color: #777;  /* Change text color for better contrast (optional) */
}
.scrolled{
    background-color: #fff !important;  /* Scrolled background color */
    color: #777;
}
.no-logo{
    color: #f6383f;
    font-weight: 800;
    font-size: 25px;
}
/* end of navbar */
/*start of carousel*/
.carousel-section{
    height: 600px !important;
    top:0;
}
.carousel-item img{
    /* height: 600px; */
    width: 100%;
    height: 700px; /* Set your desired height */
    object-fit:cover; /* Ensure the image covers the area */
    top:0;
}
.carousel-info{
    color: #fff;
    top: 250px !important;
    position: absolute;
    left:10%
}
.carousel-info h1{
    font-size: 48px;
    font-weight: 500;
}
.carousel-info{
    font-size: 16px;
    font-weight: 400;
}
.contact-btn{
    border-color: #f6383f;
    color: #fff;
    border-radius: 300px;
    padding: 15px 30px;
    margin-right: 15px;
    background-color: #f6383f;
    text-decoration: none;
}
.contact-btn:hover{
    background-color: #c92328;
}
.reserve-btn{
    border-color: #f6383f;
    color: #f6383f;
    border-radius: 300px;
    padding: 15px 30px;
    background-color: #fff;
    text-decoration: none;
}
.reserve-btn:hover{
    background-color: #f6383f;
    color: #fff;
    
}
/*end of carousel*/
/* start of social section*/
.social-section{
    background-color: #f6f6f6;
}

/* end of social section */
/* start of about section */
.about-section{
    margin-top: 220px;
    margin-bottom: 80px;
}
.section-title {
    /* float: left; */
    margin-right: 20px;
}
.section-description {
    border-left: 3px solid #f6383f;
    padding: 0 0 0 20px;
    line-height: 24px;
    float: left;
    max-width: 950px;
    color: #777;
}
.about-row{
    margin-top: 50px;
}
.about-us-image {
    margin: 40px 0 0 0;
}
.personal-details {
    margin: 40px 0 0 40px;
    font-size: 1.3rem;
    /* font-family: bold; */
    line-height: 40px;
}
.img-responsive{
    display: block;
    max-width: 100%;
    height: auto;
}
.icon-color{
    color:#f6383f;
}
/* end of about section */
/*start of customers section*/
.count {
    background: url('/public/dark-gym-full.jpg');
    /* height: 200px; */
    
    padding: 50px 0;
    margin: 100px 0;
    color: #f7f7f7;
}
@media screen and (min-width: 900px) {
    .count {
        background: url('/public/dark-gym.jpg');
        height: 200px;
    }
  }
.count-box {
    overflow: hidden;
    margin: 20px auto;
    text-align: center;
}
/* end of customers section */
/* start of program section */
/* .program-section{
    margin-bottom: 100px;
} */
.program-title{
    margin-bottom: 50px;
}
.SectionSubTitle {
    font-weight: lighter;
    text-align: left;
    font-size: 1.75em;
    color: #a19999;
    line-height: 1.9em;
}

.section-part-grey{
    background-color: #f7f7f7;
    padding: 80px 0 80px 0;
}
.section-part-white{
    padding: 80px 0 80px 0;
}
/* end of program section */
/* start of contact and social section */
.contact-section {
    background: url('https://dfn.degwysfitnessandnutrition.com/images/world-map-bg449b.jpg');
    height: 400px;
}
.sectionClass {
    padding: 20px 0px 50px 0px;
    position: relative;
    display: block;
}
.projectFactsWrap {
    display: flex;
    margin-top: 30px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.fullWidth {
    width: 100% !important;
    display: table;
    float: none;
    padding: 0;
    min-height: 1px;
    height: 100%;
    position: relative;
}
.projectFactsWrap .item {
    width: 25%;
    height: 100%;
    padding: 50px 0px;
    text-align: center;
}
a{
    text-decoration: none;
}
.projectFactsWrap .item i {
    vertical-align: middle;
    font-size: 50px;
    color: #282828;
    padding: 13px;
}
.projectFactsWrap .item p {
    color: #282828;
    font-size: 18px;
    margin: 0;
    padding: 10px;
    text-align: center !important;
}
.stat__button {
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 2rem;
    margin-top: -2em;
    width: 200px;
    text-align: center;
}
.red__button {
    background: #e52325;
    border: 0;
    padding: 1rem 1.5rem;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 12px;
    /* width: auto; */
}
button {
    cursor: pointer;
}
/* end of contact and social section */
/* start of reserve */

@media screen and (min-width: 700px) {
    .payment{
        padding: 0 25%;
    }
}
/* end of reserve */
/* start of footer */
 .colum ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-weight: 100;
}
.copyright {
    font-size: 14px;
    color: #ababab;
    text-transform: capitalize;
    text-align: center;
    margin: 20px 0 0;
}


footer {
    background-color: #333;
    color: #fff;
    display: flex;
    justify-content: space-around;
    padding: 20px;
}

.footer-column {
    flex: 1;
    padding: 10px;
    text-align: center;
}

.footer-column h3 {
    margin-bottom: 10px;
    margin: 0;
    /* padding: 0; */
    font-size: 12px;
    line-height: 17px;
    padding: 20px 0px 5px 0px;
    color: rgba(255, 255, 255, 1);
    font-weight: bold;
    text-transform: uppercase;
    /* letter-spacing: 0.250em; */
}

.footer-column p, .footer-column a {
    margin-bottom: 5px;
    color: #fff;
    text-decoration: none;
}

.footer-column a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    footer {
        flex-direction: column;
        align-items: center;
    }

    .footer-column {
        margin-bottom: 20px;
    }
}
/* end of footer */